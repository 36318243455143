import axios from "@/axios/axiosConfig";

export const pageTrainingMaterials = (params) => {
  return axios({
    url: "/trainingMaterials/pageTrainingMaterials",
    method: "post",
    data: params
  });
};

export const listTrainingMaterialsType = () => {
  return axios({
    url: "/trainingMaterialsType/listTrainingMaterialsType",
    method: "get"
  });
};

export const getTrainingMaterials = (params) => {
  return axios({
    url: `/trainingMaterials/getTrainingMaterials/${params.trainingMaterialsId}`,
    method: "get"
  });
};
