<template>
  <div class="app-box">
    <div class="header-box">
      <i class="menu-icon" @click="popupShow=!popupShow;"></i>
      <p>
        <x-search placeholder="请输入培训资料标题" @click.native="searchEvent"></x-search>
      </p>
    </div>
    <x-scroll-view :top="0.52" bg="#F6F8FB" @scrollLoadData="scrollLoadData">
      <ul v-if="xhrData.length>0" class="list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="list-item" @click="itemEvent(item.id);">
          <p :class="{'new-icon':!item.isRead}">{{item.title}}</p>
          <p>{{item.updateTime}}</p>
        </li>
      </ul>
      <x-no-data v-if="ajaxEnd&&!isNextPage"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0&&pageNum<=1"></x-no-data-bg>
    </x-scroll-view>
    <mt-popup v-model="popupShow" position="left">
      <div class="menu-box">
        <ul class="menu-list">
          <li v-for="(item,index) in menuList" :key="index" class="menu-item" @click="menuItemEvent(item.id);">{{item.typeName}}</li>
        </ul>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import search from "@/components/customerCenter/search";
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { Popup } from "mint-ui";
import { page } from "@/script/mixins/page";
import { cachePage } from "@/script/mixins/cachePage";
import { listTrainingMaterialsType, pageTrainingMaterials } from "@/api/university";

export default {
  data () {
    return {
      popupShow: false,
      typeId: null,
      menuList: []
    };
  },
  mixins: [page, cachePage],
  created () {
    document.title = "";
    document.title = "前景大学";
    this.getMenuData();
    this.getDataList();
  },
  methods: {
    menuItemEvent (id) {
      this.typeId = id;
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList();
      this.popupShow = !this.popupShow;
    },
    getDataList (isReset = true) {
      pageTrainingMaterials({
        typeId: this.typeId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        const listData = xhr.data.records;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
      });
    },
    getMenuData () {
      listTrainingMaterialsType().then(xhr => {
        this.menuList = xhr.data;
      });
    },
    itemEvent (id) {
      this.$router.push({
        path: `/university/detail/${id}?code=${this.$route.query.code}&state=${this.$route.query.state}`
      });
    },
    searchEvent () {
      this.$router.push({
        path: "/searchPage/university"
      });
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg,
    [search.name]: search,
    [Popup.name]: Popup
  }
};
</script>

<style scoped>
.header-box {
  display: flex;
  height: 0.52rem;
}
.menu-icon {
  padding: 0 0.01rem 0 0.16rem;
  width: 0.2rem;
  background: url('~@images/menu_icon.png') no-repeat right center/0.2rem
    0.16rem;
}
.header-box p {
  width: 100%;
}
.list-box {
  margin: 0.1rem 0.15rem 0;
  background-color: #ffffff;
  border-radius: 0.08rem;
}
.list-item {
  border-bottom: solid 0.01rem #f6f8fb;
  width: 3.21rem;
  margin: auto;
  line-height: 1;
  padding: 0.16rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-item p:first-of-type {
  font-size: 0.15rem;
  color: #30353d;
}
.list-item p:last-of-type {
  font-size: 0.12rem;
  color: #afb2b9;
}
.new-icon {
  padding-right: 0.3rem;
  background: url('~@images/new_icon.png') no-repeat right center/0.26rem
    0.13rem;
}
.menu-box {
  width: 1.88rem;
}
.mint-popup-left {
  height: 100%;
}
.menu-item {
  padding: 0.18rem 0 0.18rem 0.15rem;
  line-height: 1;
  font-size: 0.15rem;
  color: #30353d;
  border-bottom: solid 0.01rem #f6f8fb;
}
</style>
